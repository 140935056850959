import React from 'react';
import classnames from 'classnames';

import { LazyImg } from '../../hooks/useLazyLoad';

import classes from './Certificates.scss';

type Props = {
    isFooter?: boolean;
    className?: string;
};

export const Certificates: React.FC<Props> = ({ isFooter = false, className }) => (
    <div
        className={classnames(classes.certificates, className, {
            [classes.footer]: isFooter,
        })}
    >
        <LazyImg className={classes.bsi} src="../../../static/img/iso.jpg" />
        <LazyImg className={classes.bsi} src="../../../static/img/bsi.jpg" />
        <LazyImg className={classes.cyber} src="../../../static/img/cyber-essentials.png" />
    </div>
);
