import React from 'react';
import { SplitSdk, SplitFactory } from '@splitsoftware/splitio-react';
import { useDeps } from 'src/hooks/useDeps';
import { v4 as uuidv4 } from 'uuid';

import { environments } from '../../env';

export const Splitio: React.FC = ({ children }) => {
    const deps = useDeps();

    //const isProd = deps.env.RELEASE_STAGE === 'production';
    // const authorizationKey: string | any = isProd
    //     ? environments['master'].SPLITIO_AUTHORIZAYION_KEY
    //     : environments['cypress'].SPLITIO_AUTHORIZAYION_KEY;

    const authorizationKey: string | any = environments['master'].SPLITIO_AUTHORIZAYION_KEY;

    const factory: SplitIO.ISDK = SplitSdk({
        core: {
            authorizationKey,
            key: uuidv4(),
        },
    });

    return (
        <SplitFactory factory={factory}>
            <div>{children}</div>
        </SplitFactory>
    );
};
